import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateFilter.css";
import moment from "moment";


const DateFilter = ({setFormatedDateRange, startDate, endDate, setStartDate, setEndDate}) => {
    const dateFormater = (dates)=>{
        if (dates[0] && dates[1]) {
            setFormatedDateRange([moment(dates[0]).format("DD-MM-YYYY"), moment(dates[1]).format("DD-MM-YYYY")]);
        }
    }

    // Define a function to handle date changes
    const handleDateChange = (dates) => {
        setStartDate(dates[0]);
        setEndDate(dates[1]);
        dateFormater(dates);
    };

    return (
        <div className="Date-filter-inner-container">
            <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                maxDate={new Date()}
                isClearable={true}
                dateFormat="dd-MM-yyyy"
                clearButtonClassName="clear"
                placeholderText={"Prev 4 Days-Today"} 
            />
        </div>
    );
};

export default DateFilter;
