import './TradeScreen.css'
import React, {useState, useEffect} from "react";
import { Auth } from "aws-amplify";
import { ProgressBar } from 'react-loader-spinner';
import putstopsApi from '../api/putstopsApi';
import toast from 'react-hot-toast';
import PullToRefresh from 'react-simple-pull-to-refresh';

function TradeScreen({positions, setPositions, KiteConnect}) {

  const [zerodhaFullAccessToken, setZerodhaFullAccessToken] = useState('');
  const [zerodhaAccessToken, setZerodhaAccessToken] = useState('');
  const [awsToken, setAwsToken] = useState('');
  const [zerodhaLastUpdate, setZerodhaLastUpdate] = useState('');
  const [zerodhaApiKey, setZerodhaApiKey] = useState('');
  const [dateTime, setDateTime] = useState(new Date(2023,1,1));
  const [compareDateTime, setCompareDateTime] = useState(new Date());
  const [currentDateTime, setCurrentDateTime] = useState(new Date(compareDateTime.getFullYear(), compareDateTime.getMonth(), compareDateTime.getDate(),8,30,0));
  const [isAuthenticated, setIsAuthenticated] = useState<boolean|null>(null);
  const [totp, setTotp] = useState('');
  const [isPositionsOpen, setIsPositionsOpen] = useState(false);
  const [instrumentIdList, setInstrumentIdList] = useState<string[]>([]);
  const [prices, setPrices] = useState({});
  const [loading, setLoading] = useState(false);
  const [pnl, setPnl] = useState<number>(0);
  const [openingBalance, setOpeningBalance] = useState(null);
  const [stoplosses, setStoplosses] = useState({});
  const [partialExitClicked, setPartialExitClicked] = useState(false);
  const [exitClicked, setExitClicked] = useState(false);

  function AuthenticateBroker(e) {
    e.preventDefault();
    Auth.currentSession().then(res=>{
      let idToken = res.getIdToken();
      setAwsToken(idToken.getJwtToken());
      fetch('https://p88trwvzj9.execute-api.ap-south-1.amazonaws.com/Development?pin=' + totp, {headers: {
      'Content-type': 'application/json',
      'Authorization': awsToken, // notice the Bearer before your token
    }
    })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setZerodhaFullAccessToken(data['access_token']);
          setZerodhaAccessToken(zerodhaFullAccessToken.split('|')[0]);
          setZerodhaLastUpdate(zerodhaFullAccessToken.split('|')[1]);
          setDateTime(new Date(parseInt(data['access_token'].split('|')[1].split(' ')[0].split('-')[0]),parseInt(data['access_token'].split('|')[1].split(' ')[0].split('-')[1])-1,parseInt(data['access_token'].split('|')[1].split(' ')[0].split('-')[2]),parseInt(data['access_token'].split('|')[1].split(' ')[1].split(':')[0]),parseInt(data['access_token'].split('|')[1].split(' ')[1].split(':')[1]),parseInt(data['access_token'].split('|')[1].split(' ')[1].split(':')[2])))
          setCompareDateTime(new Date());
          setCurrentDateTime(new Date(compareDateTime.getFullYear(), compareDateTime.getMonth(), compareDateTime.getDate(),8,30,0));
        })
        .catch((err) => {
          console.log(err.message);
        });
      }
    )
  }

  useEffect(() => {
    setLoading(true);
    Auth.currentUserInfo()
      .then((response)=> {
        setZerodhaFullAccessToken(response['attributes']['custom:zerodha_access_key']);
        setZerodhaAccessToken(response['attributes']['custom:zerodha_access_key'].split('|')[0]);
        setZerodhaLastUpdate(response['attributes']['custom:zerodha_access_key'].split('|')[1]);
        setZerodhaApiKey(response['attributes']['custom:zerodha_api_key'])
        setDateTime(new Date(parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[0].split('-')[0]),parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[0].split('-')[1])-1,parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[0].split('-')[2]),parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[1].split(':')[0]),parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[1].split(':')[1]),parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[1].split(':')[2])))
        setCompareDateTime(new Date());
        setCurrentDateTime(new Date(compareDateTime.getFullYear(), compareDateTime.getMonth(), compareDateTime.getDate(),8,30,0));
        setLoading(false);
      }).catch((e) => {
        console.log(e);
        setLoading(false);
      })
  },[]);

  useEffect(() => {
    if (dateTime >= currentDateTime) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    };
  },[dateTime, currentDateTime])

  useEffect(() => {
    if (isAuthenticated) {
      getPositions();
    }
  },[isAuthenticated])

  useEffect(() => {
    let pnl = 0;
      if (openingBalance) {
        for (let i=0; i<positions.length; i++) {
          pnl = pnl + (positions[i]['day_sell_value'] - positions[i]['day_buy_value']) + (positions[i]['quantity']*positions[i]['multiplier']*prices[positions[i]['instrument_token']])
      }
      setPnl((pnl/openingBalance)*100)
    }
  },[prices])

  const getPositions = () => {
    Auth.currentSession().then(res=>{
      let idToken = res.getIdToken();
      setAwsToken(idToken.getJwtToken());
      fetch('https://56hmn94z02.execute-api.ap-south-1.amazonaws.com/Development', {headers: {
      'Content-type': 'application/json',
      'Authorization': idToken.getJwtToken(), // notice the Bearer before your token
    }
    })
        .then((response) => response.json())
        .then((data) => {
          setOpeningBalance(data['available_margin']);
          setPositions(data['positions']['data']['day']);
          setStoplosses(data['stoplosses']);
          for (let i=0; i < data['positions']['data']['day'].length; i++) {
            if (data['positions']['data']['day'][i]['quantity'] > 0) {
              setIsPositionsOpen(true);
              break;
            } 
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      }
    )
  }

  useEffect(() => {
    if (positions.length > 0) {
      for (let i=0; i < positions.length; i++) {
        setInstrumentIdList(oldInstrumentIdList => [...oldInstrumentIdList, positions[i]['instrument_token']]);
      }
      connectToSocket();
    }
    
  },[positions])

    // const [openPositionInstrumentIdList, setopenPositionInstrumentIdList] = React.useState();
    
      const connectToSocket = () => {
              // Connect to kiteconnect websocket
      const socketLink = "wss://ws.kite.trade?api_key="+ zerodhaApiKey +"&access_token=" + zerodhaAccessToken;
      var ws = new WebSocket(socketLink);

      var message = { a: "mode", v: ["ltp", instrumentIdList] };

      ws.onopen = function(e) {
        console.log("[open] Connection established");
        console.log("Sending to server");
        ws.send(JSON.stringify(message));  
      };


      // Log the Ltp on receiving data
      ws.onmessage = async function(event) {
        try {
          let jsonData = JSON.parse(event.data);
          if (jsonData.type === "order") {
            console.log("Received order update", jsonData);
            getPositions();
            if (jsonData.data.status === "COMPLETE") {
              toast('ORDER '+jsonData.data.status,
              {
                icon: '☑️',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }
            );
            }
          }
        } catch (e) {}
        try {
          let data = await event.data.arrayBuffer();
            let instrumentId: string = (new DataView(data.slice(4,8)).getInt32(0)).toString();
            let updatedPrice = (new DataView(data.slice(8,12)).getInt32(0)/100).toString();
            let updatedValue = {};
            updatedValue[instrumentId] = updatedPrice;
            setPrices(Prices => ({
              ...Prices,
              ...updatedValue
            }));
        } catch (e) {
          console.log("ERROR", e);
        }
        
      };
    }

    function PartialExit() {
      setPartialExitClicked(true);
      putstopsApi.get('/partial-exit').then((resp) => {
        setPartialExitClicked(false);
        toast('Partially Exited!',
          {
            icon: '☑️',
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          }
        );
        getPositions();
      }).catch((e)=> {
        console.log(e);
        toast('Order Failed!',
          {
            icon: '❎',
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          }
        );
        setPartialExitClicked(false);
      })
    }
  
    function Exit() {
      setExitClicked(true);
      putstopsApi.get('/exit').then((resp) => {
        setExitClicked(false);
        toast('Exited!',
          {
            icon: '☑️',
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          }
        );
        getPositions();
      }).catch((e)=>{
        console.log(e);
        toast('Order Failed!',
          {
            icon: '❎',
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          }
        );
        setExitClicked(false);
      })
    }

    async function handleRefresh() {
      await getPositions();
    }
    
    return (
      <div>
        {
          !isAuthenticated && !loading && isAuthenticated !== null &&
          <div style={{height: '80vh', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center'}}>
            <div>
              User is not authenticated with broker. Please Enter TOTP to continue trading
            </div>
            <div>
              <form onSubmit={(e) => AuthenticateBroker(e)} style={{display: 'flex', 'flexDirection':'column', textAlign: 'center', alignContent: 'center', justifyContent: 'center'}}>
                <label>
                  <input onChange={(e) => setTotp(e.target.value)} value={totp} autoFocus style={{height: '20px', marginTop: '20px'}} type="number" name="totp" />
                </label>
                <input style={{width: '100px', alignSelf: 'center', marginTop: '20px', color: 'white', backgroundColor: 'black', borderRadius: '10px'}} type="submit" value="Submit" />
              </form>
            </div>
          </div>
        }
        {
          isAuthenticated && !loading && isAuthenticated !== null &&
          <PullToRefresh className="pullToRefresh" onRefresh={handleRefresh}>
            <div className="container">
              {
                isPositionsOpen &&
                <div style={{color:'#D3D3D3'}} className='positions'>
                  Positions Open
                </div>
              }
              {
                !isPositionsOpen &&
                <div style={{color:'#D3D3D3'}} className='positions'>
                  Positions Closed
                </div>
              }
              {
                positions &&
                positions.map(position=>(
                  <div className="all-positions" style={position['quantity'] > 0? {color: 'white'}: {color: 'black'}}>
                    {position['tradingsymbol']}: {prices[position['instrument_token']]}   {position['quantity']>0?stoplosses[position['instrument_token']]:'N/A'}
                  </div>
                ))
                
              } 
              <div className='pnl'>
            <span style={pnl < 0? {color: '#D3D3D3'}: {color: 'black'}}>{pnl.toFixed(2)}</span>
            
          </div>
          <div>
            <button className="partial-exit-button" disabled={partialExitClicked} onClick={PartialExit} style={{ opacity: partialExitClicked ? 0.5 : 1, transition: 'opacity 300ms ease' }}>
              Partial Exit
            </button>
          </div>
          <div>
            <button className="exit-button" disabled={exitClicked} onClick={Exit} style={{ opacity: exitClicked ? 0.5 : 1, transition: 'opacity 300ms ease' }}>
              Exit
            </button>
          </div>
            </div>
            </PullToRefresh>
        }
        <ProgressBar
          visible={loading}
          height="250"
          width="250"
          barColor="black"
          borderColor="#D3D3D3"
          ariaLabel="progress-bar-loading"
        />
      </div>
    );
  }
  
  export default TradeScreen;