import './AnalyticsScreen.css'
import React, {useState, useEffect} from "react";
import { Auth } from "aws-amplify";
import { ProgressBar } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import PullToRefresh from 'react-simple-pull-to-refresh';
import DateFilter from './DateFilter';
import moment from "moment";
import RenderLineChart from './RenderLineChart';

function AnalyticsScreen({positions, setPositions, KiteConnect}) {

  const data1: any = [{name: 'Monday', profit: 400},{name: 'Tuesday', profit: 200},{name: 'Wednesday', profit: 400},{name: 'Thursday', profit: 100},{name: 'Friday', profit: 300}];
  const data2: any = [{name: 'Monday', profit: 300},{name: 'Tuesday', profit: 100},{name: 'Wednesday', profit: 400},{name: 'Thursday', profit: 200},{name: 'Friday', profit: 400}];
  const data: any = {
    "data1": data1,
    "data2": data2
  }

  const [zerodhaFullAccessToken, setZerodhaFullAccessToken] = useState('');
  const [zerodhaAccessToken, setZerodhaAccessToken] = useState('');
  const [awsToken, setAwsToken] = useState('');
  const [zerodhaLastUpdate, setZerodhaLastUpdate] = useState('');
  const [zerodhaApiKey, setZerodhaApiKey] = useState('');
  const [dateTime, setDateTime] = useState(new Date(2023,1,1));
  const [compareDateTime, setCompareDateTime] = useState(new Date());
  const [currentDateTime, setCurrentDateTime] = useState(new Date(compareDateTime.getFullYear(), compareDateTime.getMonth(), compareDateTime.getDate(),8,30,0));
  const [isAuthenticated, setIsAuthenticated] = useState<boolean|null>(null);
  const [totp, setTotp] = useState('');
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState(null);
  const currentDate = new Date();
  const [startDate, setStartDate] = useState<Date>(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [formatedDateRange, setFormatedDateRange] = useState([moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)).format("DD-MM-YYYY"), moment(new Date()).format("DD-MM-YYYY")]);

  function AuthenticateBroker(e) {
    e.preventDefault();
    Auth.currentSession().then(res=>{
      let idToken = res.getIdToken();
      setAwsToken(idToken.getJwtToken());
      fetch('https://p88trwvzj9.execute-api.ap-south-1.amazonaws.com/Development?pin=' + totp, {headers: {
      'Content-type': 'application/json',
      'Authorization': awsToken, // notice the Bearer before your token
    }
    })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setZerodhaFullAccessToken(data['access_token']);
          setZerodhaAccessToken(zerodhaFullAccessToken.split('|')[0]);
          setZerodhaLastUpdate(zerodhaFullAccessToken.split('|')[1]);
          setDateTime(new Date(parseInt(data['access_token'].split('|')[1].split(' ')[0].split('-')[0]),parseInt(data['access_token'].split('|')[1].split(' ')[0].split('-')[1])-1,parseInt(data['access_token'].split('|')[1].split(' ')[0].split('-')[2]),parseInt(data['access_token'].split('|')[1].split(' ')[1].split(':')[0]),parseInt(data['access_token'].split('|')[1].split(' ')[1].split(':')[1]),parseInt(data['access_token'].split('|')[1].split(' ')[1].split(':')[2])))
          setCompareDateTime(new Date());
          setCurrentDateTime(new Date(compareDateTime.getFullYear(), compareDateTime.getMonth(), compareDateTime.getDate(),8,30,0));
        })
        .catch((err) => {
          console.log(err.message);
        });
      }
    )
  }

  useEffect(() => {
    setLoading(true);
    Auth.currentUserInfo()
      .then((response)=> {
        console.log(response['attributes']);
        setZerodhaFullAccessToken(response['attributes']['custom:zerodha_access_key']);
        setZerodhaAccessToken(response['attributes']['custom:zerodha_access_key'].split('|')[0]);
        setZerodhaLastUpdate(response['attributes']['custom:zerodha_access_key'].split('|')[1]);
        setZerodhaApiKey(response['attributes']['custom:zerodha_api_key'])
        setDateTime(new Date(parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[0].split('-')[0]),parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[0].split('-')[1])-1,parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[0].split('-')[2]),parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[1].split(':')[0]),parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[1].split(':')[1]),parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[1].split(':')[2])))
        setCompareDateTime(new Date());
        setCurrentDateTime(new Date(compareDateTime.getFullYear(), compareDateTime.getMonth(), compareDateTime.getDate(),8,30,0));
        setLoading(false);
      }).catch((e) => {
        console.log(e);
        setLoading(false);
      })
  },[]);

  useEffect(() => {
    if (dateTime >= currentDateTime) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    };
  },[dateTime, currentDateTime])

  useEffect(() => {
    if (formatedDateRange[0] && formatedDateRange[1]) {
        getStatistics();
    }
  },[formatedDateRange])

  const getStatistics = () => {
    Auth.currentSession().then(res=>{
      let idToken = res.getIdToken();
      setAwsToken(idToken.getJwtToken());
      setLoading(true);
      Auth.currentUserInfo()
      .then((response)=> {
        const attributes = response['attributes'];
        fetch(`https://kihgd93mr6.execute-api.ap-south-1.amazonaws.com/Development?start_date=${formatedDateRange[0]}&end_date=${formatedDateRange[1]}&symbol=${attributes['custom:instrument_to_trade']}`, {headers: {
        'Content-type': 'application/json',
        'Authorization': idToken.getJwtToken(), // notice the Bearer before your token
      }
      })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false)
              setStatistics(data);
              console.log(data)
          })
          .catch((err) => {
            setLoading(false);
            toast('No data to fetch!',
              {
                icon: '❎',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }
            );
          });
        }
      )
    })
  }

    async function handleRefresh() {
      await getStatistics();
    }
    
    return (
      <div style={{'height': '100%'}}>
        {
          !loading &&
          <PullToRefresh className="pullToRefresh" onRefresh={handleRefresh}>
              <div className="analytics-page-container">
                <div className="date-filter-main-container">
                    <label className="Label-text-status-filter">Select Date:</label>
                    <div><DateFilter setFormatedDateRange={setFormatedDateRange} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} /></div>
                </div>
                <div className="analytics-container">
                <div>
                    <div>
                        Total Pnl
                    </div>
                    <div className='pnl'>
                        <span style={{color: 'black'}}>{statistics? statistics['overview']['total_pnl']: 'N/A'}</span>
                    </div>
                </div>
                <div>
                    <div>
                        Profit Trades
                    </div>
                    <div className='pnl'>
                        <span style={{color: 'black'}}>{statistics? statistics['overview']['profit_n']: 'N/A'}</span>
                    </div>
                </div>
                <div>
                    <div>
                        Loss Trades
                    </div>
                    <div className='pnl'>
                        <span style={{color: 'black'}}>{statistics? statistics['overview']['loss_n']: 'N/A'}</span>
                    </div>
                </div>
                <div>
                    <div>
                        Avg. Profit
                    </div>
                    <div className='pnl'>
                        <span style={{color: 'black'}}>{statistics? statistics['overview']['profit_avg']: 'N/A'}</span>
                    </div>
                </div>
                <div>
                    <div>
                        Avg. Loss
                    </div>
                    <div className='pnl'>
                        <span style={{color: 'black'}}>{statistics? statistics['overview']['loss_avg']: 'N/A'}</span>
                    </div>
                </div>
                <div>
                    <div>
                        Win %
                    </div>
                    <div className='pnl'>
                        <span style={{color: 'black'}}>{statistics? statistics['overview']['win_percent']: 'N/A'}</span>
                    </div>
                </div>
                <div>
                    <div>
                        Max Profit
                    </div>
                    <div className='pnl'>
                        <span style={{color: 'black'}}>{statistics? statistics['overview']['profit_max']: 'N/A'}</span>
                    </div>
                </div>
                <div>
                    <div>
                        Max Loss
                    </div>
                    <div className='pnl'>
                        <span style={{color: 'black'}}>{statistics? statistics['overview']['loss_max']: 'N/A'}</span>
                    </div>
                </div>
                <div>
                    <div>
                        Time in Profit
                    </div>
                    <div className='pnl'>
                        <span style={{color: 'black'}}>{statistics? statistics['overview']['profit_time_avg']: 'N/A'}</span>
                    </div>
                </div>
                <div>
                    <div>
                        Time in Loss
                    </div>
                    <div className='pnl'>
                        <span style={{color: 'black'}}>{statistics? statistics['overview']['loss_time_avg']: 'N/A'}</span>
                    </div>
                </div>
            </div>
            {
              data &&
              <div style={{height: '100%', width: '100%', marginTop: '30px'}}>
                <RenderLineChart data={statistics? statistics['weekly_stats']: null}/>
              </div>
            }
            {
              data &&
              <div style={{height: '100%', width: '100%', marginTop: '30px'}}>
                <RenderLineChart data={statistics? statistics['equity_curve']: null}/>
              </div>
            }
                  
              </div>
            
            </PullToRefresh>
        }
        <ProgressBar
          visible={loading}
          height="250"
          width="250"
          barColor="black"
          borderColor="#D3D3D3"
          ariaLabel="progress-bar-loading"
        />
      </div>
    );
  }
  
  export default AnalyticsScreen;