import './Login.css';
import React from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import { useInput } from "./../utils/forms";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useNavigate } from "react-router-dom";

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

function Login() {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");

  const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setLoading(true);

    try {
      Auth.signIn(email, password).then(()=> {
        console.log("Login Successful");
        navigate("/");
      })
    } catch (error: any) {
      console.log("Error!!", error.message, "danger");
    }
    setLoading(false);
  };

  return (
    <body>
      <form
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
      onSubmit={handleSubmit}
    >
      <h1 style={{ fontSize: "22px", fontWeight: 800}}>
        {" "}
        Sign in to an existing account
      </h1>
      <Field label="Email" {...bindEmail} type="email" />
      <Field label="Password" type="password" {...bindPassword} />
      <Button
        variant="contained"
        style={{background: "#282c34", color:"white"}}
        size="large"
        type="submit"
        disabled={loading}
      >
        {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
        Login to Your Account
      </Button>
      <DLink to="/signup" style={{color:"#282c34"}}>make a new account &rarr;</DLink>
    </form>
    </body>
    
  );



}

export default Login;