import { Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";

const ProtectedRoute = ({ showNav, setShowNav, children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getUser = async () => {
    let user = null
    setIsLoading(true)
    try {
      user = await Auth.currentAuthenticatedUser();
      if (user) {
        setIsLoading(false);
        setIsLoggedIn(true);
        setShowNav(true);
      } else {
        setIsLoading(false);
        setIsLoggedIn(false);
        setShowNav(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      setIsLoggedIn(false);
      setShowNav(false);
    }
  }

  useEffect(() => {
    getUser()
  },[])
  
  if (!isLoading) {
    if (isLoggedIn) {
      return children
    } else {
      console.log("login check")
      console.log(isLoggedIn);
      return <Navigate to="/login" replace />;
    }
  }
  
};





export default ProtectedRoute;