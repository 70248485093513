import axios from "axios";
import { Auth } from "aws-amplify";

const putstopsApi = axios.create({
    baseURL: `https://api.putstops.com`,
    // baseURL: `http://127.0.0.1:5000`
});

// Add an interceptor to the Axios instance
putstopsApi.interceptors.request.use(
    async (config) => {
        try {
            // Get the JWT token using AWS Amplify's Auth.currentSession()
            const session = await Auth.currentSession();
            const idToken = session.getIdToken();
            const jwtToken = idToken.getJwtToken();
            // Add the JWT token to the request headers
            config.headers['Authorization'] = jwtToken;
        } catch (error) {
            // Handle any errors, e.g., token retrieval failed
            console.error('Error while setting JWT token:', error);
        }

        return config;
    },
    (error) => {
        // Handle any request errors
        return Promise.reject(error);
    }
);

export default putstopsApi;