import './SettingsScreen.css';
import { Auth } from "aws-amplify";
import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';


async function logOut(navigate) {
    try {
      Auth.signOut().then((resp) => {
        navigate(0);
      });
      
  } catch (error) {
      console.log('error signing out: ', error);
  }
}

function SettingsScreen() {
    const navigate = useNavigate();
    const [zerodhaUserId, setZerodhaUserId] = useState('');
    const [zerodhaApiKey, setZerodhaApiKey] = useState('');
    const [accountRisk, setAccountRisk] = useState('');
    const [maxPositions, setMaxPositions] = useState('');
    const [maxTrades, setMaxTrades] = useState('');
    const [tradeRisk, setTradeRisk] = useState('');
    const [stopPeriod, setStopPeriod] = useState('');
    const [noOfCandles, setNoOfCandles] = useState('');
    const [expDayDiff, setExpDayDiff] = useState('');
    const [instrumentToTrade, setInstrumentToTrade] = useState('');
    const [orderTimeGap, setOrderTimeGap] = useState('');
    const [includeCurrentCandle, setIncludeCurrentCandle] = useState('');

    const startTime = 9;
    const endTime = 16;

    useEffect(() => {
      Auth.currentUserInfo()
      .then((response)=> {
        const attributes = response['attributes'];
        setZerodhaUserId(attributes['custom:zerodha_user_id']);
        setZerodhaApiKey(attributes['custom:zerodha_api_key']);
        setAccountRisk(attributes['custom:loss_limit']);
        setMaxPositions(attributes['custom:max_open_position']);
        setMaxTrades(attributes['custom:max_trades']);
        setTradeRisk(attributes['custom:riskpct']);
        setStopPeriod(attributes['custom:stopperiod']);
        setNoOfCandles(attributes['custom:stopperiod_n']);
        setExpDayDiff(attributes['custom:expiry_day_diff']);
        setInstrumentToTrade(attributes['custom:instrument_to_trade']);
        setOrderTimeGap(attributes['custom:order_time_gap']);
        setIncludeCurrentCandle(attributes['custom:include_curr_candle'])
      })
    },[]);

    const saveDetails = async() => {
      const user = await Auth.currentAuthenticatedUser();
      const today = new Date();
      if (startTime < today.getHours() && today.getHours() < endTime) {
        toast('Cannot update important values, only changes to days to expiry and instrument!',
            {
              icon: '❎',
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              },
            }
        );
        Auth.updateUserAttributes(
          user,
          {
            'custom:expiry_day_diff': expDayDiff,
            'custom:instrument_to_trade': instrumentToTrade
          }
        ).then((res) => {
          toast('Updated Successfully!',
            {
              icon: '☑️',
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              },
            }
          );
        }).catch((err) => {
          toast('Something went wrong!',
            {
              icon: '❎',
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              },
            }
          );
        })
      } else {
        Auth.updateUserAttributes(
          user,
          {
            'custom:expiry_day_diff': expDayDiff,
            'custom:loss_limit': accountRisk,
            'custom:max_open_position': maxPositions,
            'custom:max_trades': maxTrades,
            'custom:riskpct': tradeRisk,
            'custom:stopperiod': stopPeriod,
            'custom:stopperiod_n': noOfCandles,
            'custom:order_time_gap': orderTimeGap,
            'custom:include_curr_candle': includeCurrentCandle
          }
        ).then((res) => {
          toast('Updated Successfully!',
            {
              icon: '☑️',
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              },
            }
          );
        }).catch((err) => {
          toast('Something went wrong!',
            {
              icon: '❎',
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              },
            }
          );
        })
      }

    }

      return <div className='settings-page-container'>
              <h2>Hi, {zerodhaUserId}!</h2>
              <div className="user-details">
                <div className='user-details-row'>
                  <div>
                    Account Risk
                  </div>
                  <input className='user-details-value' value={accountRisk} onChange={e => setAccountRisk(e.target.value)} />
                </div>
                <div className='user-details-row'>
                  <div>
                    Max Open Positions
                  </div>
                  <input className='user-details-value' value={maxPositions} onChange={e => setMaxPositions(e.target.value)} />
                </div>
                <div className='user-details-row'>
                  <div>
                    Max Trades per day
                  </div>
                  <input className='user-details-value' value={maxTrades} onChange={e => setMaxTrades(e.target.value)} />
                </div>
                <div className='user-details-row'>
                  <div>
                    Risk per trade (in %)
                  </div>
                  <input className='user-details-value' value={tradeRisk} onChange={e => setTradeRisk(e.target.value)} />
                </div>
                <div className='user-details-row'>
                  <div>
                    Stop Loss Candle Period
                  </div>
                  <input className='user-details-value' value={stopPeriod} onChange={e => setStopPeriod(e.target.value)} />
                </div>
                <div className='user-details-row'>
                  <div>
                    No. of Candles for Stop Loss
                  </div>
                  <input className='user-details-value' value={noOfCandles} onChange={e => setNoOfCandles(e.target.value)} />
                </div>
                <div className='user-details-row'>
                  <div style={{width: '60%'}}>
                    Change days to expiry in case of holiday
                  </div>
                    <input className='user-details-value' value={expDayDiff} onChange={e => setExpDayDiff(e.target.value)} />
                </div>
                <div className='user-details-row'>
                  <div style={{width: '60%'}}>
                    Instrument
                  </div>
                    <input className='user-details-value' value={instrumentToTrade} onChange={e => setInstrumentToTrade(e.target.value)} />
                </div>
                <div className='user-details-row'>
                  <div style={{width: '60%'}}>
                    Time between orders (minutes)
                  </div>
                    <input className='user-details-value' value={orderTimeGap} onChange={e => setOrderTimeGap(e.target.value)} />
                </div>
                <div className='user-details-row'>
                  <div style={{width: '60%'}}>
                    Include current candle?
                  </div>
                    <input className='user-details-value' value={includeCurrentCandle} onChange={e => setIncludeCurrentCandle(e.target.value)} />
                </div>
                <div>
                  <button className="save-button" onClick={() => saveDetails()}>
                    Save
                  </button>
                </div>
              </div>
              <button className="logout-button" onClick={() => logOut(navigate)}>
                Logout
              </button>
            </div>
  }
  
  export default SettingsScreen;