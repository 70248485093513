import './HomeScreen.css'
import { Auth } from "aws-amplify";
import React, { useState, useEffect } from 'react';
import putstopsApi from '../api/putstopsApi';
import { ProgressBar } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import PullToRefresh from 'react-simple-pull-to-refresh';

function HomeScreen({ positions, setPositions, KiteConnect }) {

  const [zerodhaFullAccessToken, setZerodhaFullAccessToken] = useState('');
  const [zerodhaAccessToken, setZerodhaAccessToken] = useState('');
  const [awsToken, setAwsToken] = useState('');
  const [zerodhaLastUpdate, setZerodhaLastUpdate] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [dateTime, setDateTime] = useState(new Date(2023, 1, 1));
  const [compareDateTime, setCompareDateTime] = useState(new Date());
  const [currentDateTime, setCurrentDateTime] = useState(new Date(compareDateTime.getFullYear(), compareDateTime.getMonth(), compareDateTime.getDate(), 8, 30, 0));
  const [isAuthenticated, setIsAuthenticated] = useState<boolean|null>(null);
  const [totp, setTotp] = useState('');
  const [isPositionsOpen, setIsPositionsOpen] = useState(false);
  const [pnl, setPnl] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [longClicked, setLongClicked] = useState(false);
  const [shortClicked, setShortClicked] = useState(false);
  const [trailClicked, setTrailClicked] = useState(false);
  const [partialExitClicked, setPartialExitClicked] = useState(false);
  const [exitClicked, setExitClicked] = useState(false);

  if (isAuthenticated) {
    var kc = new KiteConnect({
      api_key: "229pepq94k79e2cl",
      access_token: zerodhaAccessToken,
      debug: true
    });
  }


  function Long() {
    setLongClicked(true);
    putstopsApi.get('/long').then((resp) => {
      setLongClicked(false);
      if (resp.data === 'Order Failed') {
        toast('Limit Exceeded!',
        {
          icon: '❎',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      } else if (resp.data === 'Order Placed') {
        toast('Order Placed!',
        {
          icon: '☑️',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      }
      
      getPositions();
      getPnl();
    }).catch((e)=> {
      console.log(e);
      toast('Order Failed!',
        {
          icon: '❎',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      setLongClicked(false);
    })
  }

  function Short() {
    setShortClicked(true);
    putstopsApi.get('/short').then((resp) => {
      setShortClicked(false);
      if (resp.data === 'Order Failed') {
        toast('Limit Exceeded!',
        {
          icon: '❎',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      } else if (resp.data === 'Order Placed') {
        toast('Order Placed!',
        {
          icon: '☑️',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      }
      getPositions()
      getPnl();
    }).catch((e)=> {
      console.log(e);
      toast('Order Failed!',
        {
          icon: '❎',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      setShortClicked(false);
    })
  }

  function Trail() {
    setTrailClicked(true);
    putstopsApi.get('/trail').then((resp) => {
      setTrailClicked(false);
      toast('Order Trailed!',
        {
          icon: '☑️',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      getPositions();
      getPnl();
    }).catch((e)=> {
      console.log(e);
      toast('Order Failed!',
        {
          icon: '❎',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      setTrailClicked(false);
    })
  }

  function PartialExit() {
    setPartialExitClicked(true);
    putstopsApi.get('/partial-exit').then((resp) => {
      setPartialExitClicked(false);
      toast('Partially Exited!',
        {
          icon: '☑️',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      getPositions();
      getPnl();
    }).catch((e)=> {
      console.log(e);
      toast('Order Failed!',
        {
          icon: '❎',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      setPartialExitClicked(false);
    })
  }

  function Exit() {
    setExitClicked(true);
    putstopsApi.get('/exit').then((resp) => {
      setExitClicked(false);
      toast('Exited!',
        {
          icon: '☑️',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      getPositions();
      getPnl();
    }).catch((e)=>{
      console.log(e);
      toast('Order Failed!',
        {
          icon: '❎',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      setExitClicked(false);
    })
  }

  function AuthenticateBroker(e) {
    e.preventDefault();
    Auth.currentSession().then(res => {
      let idToken = res.getIdToken();
      setAwsToken(idToken.getJwtToken());
      fetch('https://p88trwvzj9.execute-api.ap-south-1.amazonaws.com/Development?pin=' + totp, {
        headers: {
          'Content-type': 'application/json',
          'Authorization': idToken.getJwtToken(), // notice the Bearer before your token
        }
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          Auth.currentAuthenticatedUser({ bypassCache: true });
          setZerodhaFullAccessToken(data['access_token']);
          setZerodhaAccessToken(zerodhaFullAccessToken.split('|')[0]);
          setZerodhaLastUpdate(zerodhaFullAccessToken.split('|')[1]);
          setDateTime(new Date(parseInt(data['access_token'].split('|')[1].split(' ')[0].split('-')[0]), parseInt(data['access_token'].split('|')[1].split(' ')[0].split('-')[1]) - 1, parseInt(data['access_token'].split('|')[1].split(' ')[0].split('-')[2]), parseInt(data['access_token'].split('|')[1].split(' ')[1].split(':')[0]), parseInt(data['access_token'].split('|')[1].split(' ')[1].split(':')[1]), parseInt(data['access_token'].split('|')[1].split(' ')[1].split(':')[2])))
          setCompareDateTime(new Date());
          setCurrentDateTime(new Date(compareDateTime.getFullYear(), compareDateTime.getMonth(), compareDateTime.getDate(), 8, 30, 0));
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    )
  }

  useEffect(() => {
    setLoading(true);
    Auth.currentUserInfo()
      .then((response) => {
        setApiSecret(response['attributes']['custom:zerodha_api_secret']);
        setZerodhaFullAccessToken(response['attributes']['custom:zerodha_access_key']);
        setZerodhaAccessToken(response['attributes']['custom:zerodha_access_key'].split('|')[0]);
        setZerodhaLastUpdate(response['attributes']['custom:zerodha_access_key'].split('|')[1]);
        setDateTime(new Date(parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[0].split('-')[0]), parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[0].split('-')[1]) - 1, parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[0].split('-')[2]), parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[1].split(':')[0]), parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[1].split(':')[1]), parseInt(response['attributes']['custom:zerodha_access_key'].split('|')[1].split(' ')[1].split(':')[2])))
        setCompareDateTime(new Date());
        setCurrentDateTime(new Date(compareDateTime.getFullYear(), compareDateTime.getMonth(), compareDateTime.getDate(), 8, 30, 0));
        setLoading(false);
      }).catch((e) => {
        console.log(e);
        setLoading(false);
      })
  }, []);

  useEffect(() => {
    if (dateTime >= currentDateTime) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    };
  }, [dateTime, currentDateTime])

  useEffect(() => {
    if (isAuthenticated) {
      getPositions();
      getPnl();
    }
  }, [isAuthenticated])

  const getPositions = () => {
    Auth.currentSession().then(res => {
      let idToken = res.getIdToken();
      setAwsToken(idToken.getJwtToken());
      fetch('https://56hmn94z02.execute-api.ap-south-1.amazonaws.com/Development', {
        headers: {
          'Content-type': 'application/json',
          'Authorization': idToken.getJwtToken(), // notice the Bearer before your token
        }
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setLoading(false);
          setPositions(data['positions']['data']['day']);
          for (let i = 0; i < data['positions']['data']['day'].length; i++) {
            if (data['positions']['data']['day'][i]['quantity'] > 0) {
              setIsPositionsOpen(true);
              break;
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    )
  }

  const getPnl = () => {
    putstopsApi.get('/pnl').then((resp) => {
      setPnl(parseFloat(resp.data));
      setLoading(false);
    })
  }

  async function handleRefresh() {
    await getPnl();
  }

  return (
    <div>
      {
        !isAuthenticated && !loading && isAuthenticated !== null &&
        <div style={{ height: '80vh', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
          <div>
            User is not authenticated with broker. Please Enter TOTP to continue trading
          </div>
          <div>
            <form onSubmit={(e) => AuthenticateBroker(e)} style={{ display: 'flex', 'flexDirection': 'column', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
              <label>
                <input onChange={(e) => setTotp(e.target.value)} value={totp} autoFocus style={{ height: '20px', marginTop: '20px' }} type="number" name="totp" />
              </label>
              <input style={{ width: '100px', alignSelf: 'center', marginTop: '20px', color: 'white', backgroundColor: 'black', borderRadius: '10px' }} type="submit" value="Submit" />
            </form>
          </div>
        </div>
      }
      {
        isAuthenticated && !loading && isAuthenticated !== null &&
        <PullToRefresh className="pullToRefresh" onRefresh={handleRefresh}>
        <div className="container">
          {
            isPositionsOpen &&
            <div style={{color:'#D3D3D3'}} className='positions'>
              Positions Open
            </div>
          }
          {
            !isPositionsOpen &&
            <div style={{color:'#D3D3D3'}} className='positions'>
              Positions Closed
            </div>
          }
          <div>
            <button className="trading-button" disabled={longClicked} onClick={Long} style={{ opacity: longClicked? 0.5 : 1, transition: 'opacity 300ms ease' }}>
              Long
            </button>
            <button className="trading-button" disabled={shortClicked} onClick={Short} style={{ opacity: shortClicked ? 0.5 : 1, transition: 'opacity 300ms ease' }}>
              Short
            </button>
          </div>
          <div>
            <button className="trail-button" disabled={trailClicked} onClick={Trail} style={{ opacity: trailClicked ? 0.5 : 1, transition: 'opacity 300ms ease' }}>
              Trail
            </button>
            <button className="partial-exit-button" disabled={partialExitClicked} onClick={PartialExit} style={{ opacity: partialExitClicked ? 0.5 : 1, transition: 'opacity 300ms ease' }}>
              Partial Exit
            </button>
          </div>
          <div>
            <button className="exit-button" disabled={exitClicked} onClick={Exit} style={{ opacity: exitClicked ? 0.5 : 1, transition: 'opacity 300ms ease' }}>
              Exit
            </button>
          </div>
          <div className='pnl'>
            <span style={pnl < 0? {color: '#D3D3D3'}: {color: 'black'}}>{pnl}</span>
          </div>
        </div>
        </PullToRefresh>
      }
        <ProgressBar
          visible={loading}
          height="250"
          width="250"
          barColor="black"
          borderColor="#D3D3D3"
          ariaLabel="progress-bar-loading"
        />
    </div>
  );
}

export default HomeScreen;