import React from 'react';
import './App.css';
import BottomNavigation from 'reactjs-bottom-navigation'
import 'reactjs-bottom-navigation/dist/index.css'
import HomeScreen from './components/HomeScreen';
import SettingsScreen from './components/SettingsScreen';
import TradeScreen from './components/TradeScreen';
import AnalyticsScreen from './components/AnalyticsScreen';
import ProtectedRoute from './services/ProtectedRoute';
import { HomeOutlined, SlidersOutlined, SettingOutlined, LineChartOutlined  } from '@ant-design/icons';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import Signup from "./components/Signup";
import Confirmation from "./components/Confirmation";
import Login from "./components/Login";
import { COGNITO } from "./configs/aws";
import { Amplify } from "aws-amplify";
import {useState} from 'react';
import { Toaster } from 'react-hot-toast';

var KiteConnect = require("kiteconnect").KiteConnect;

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

const bottomNavItems = [
  {
    title: 'Home',
    icon: <HomeOutlined style={{ fontSize: '18px' }} />,
    activeIcon: <HomeOutlined style={{ fontSize: '18px', color: '#fff' }} />
  },
  {
    title: 'Trade',
    icon: <SlidersOutlined style={{ fontSize: '18px' }} />,
    activeIcon: <SlidersOutlined style={{ fontSize: '18px', color: '#fff' }} />
  },
  {
    title: 'Analytics',
    icon: <LineChartOutlined style={{ fontSize: '18px' }} />,
    activeIcon: <LineChartOutlined style={{ fontSize: '18px', color: '#fff' }} />
  },
  {
    title: 'Settings',
    icon: <SettingOutlined style={{ fontSize: '18px' }} />,
    activeIcon: <SettingOutlined style={{ fontSize: '18px', color: '#fff' }} />
  },
]

function App() {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const [positions, setPositions] = useState([]);
  const bottomNavs = {'/': 0, '/settings': 2, '/trade': 1}
  const location = useLocation();
  console.log(bottomNavs[location.pathname]);


  return (      
    <div className="App">
      <header className="App-header">
        PutStops App
      </header>
      <Routes>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/login"element={<Login/>}/>
        <Route path="/confirmation"element={<Confirmation/>}/>
        <Route path = "/"
          element={
            <ProtectedRoute showNav={showNav} setShowNav={setShowNav}>
              <HomeScreen positions={positions} setPositions={setPositions} KiteConnect={KiteConnect}/>
            </ProtectedRoute>
            }  
        />
        <Route path = "/settings" 
          element={
            <ProtectedRoute showNav={showNav} setShowNav={setShowNav}>
              <SettingsScreen/>
            </ProtectedRoute>
          }
        />
        <Route path = "/trade" 
          element={
            <ProtectedRoute showNav={showNav} setShowNav={setShowNav}>
              <TradeScreen positions={positions} setPositions={setPositions} KiteConnect={KiteConnect}/>
            </ProtectedRoute>
          }
        />
        <Route path = "/analytics" 
          element={
            <ProtectedRoute showNav={showNav} setShowNav={setShowNav}>
              <AnalyticsScreen positions={positions} setPositions={setPositions} KiteConnect={KiteConnect}/>
            </ProtectedRoute>
          }
        />
      </Routes>
      <Toaster />
      {
        showNav &&
          <BottomNavigation
            items={bottomNavItems}
            defaultSelected={bottomNavs[location.pathname]}
            selected={2}
            activeBgColor="#282c34"
            onItemClick={(item) => {
              switch(item.title)
              {
                case "Home":
                  navigate('/')
                  break;
                case "Trade":
                  navigate('/trade')
                  break;
                case "Analytics":
                  navigate('analytics')
                  break;
                case "Settings":
                  navigate("/settings");
                  break;
              }
            }
          }
          />
      }
      
    </div>
  );
}



export default App;
